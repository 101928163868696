import React from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";

export const AccountManager = () => {
  return (
    <>
      <div className="card d-flex justify-content-center align-items-center mb-10 overflow-hidden pb-4 ">
        <div className="w-100 bg-light-secondary mb-3 text-center py-2">
          <span className="text-gray-800 fw-semibold">
            Your Account Manager
          </span>
        </div>
        <div className="d-flex align-items-center mx-4">
          <div className="symbol symbol-50px me-4">
            <img
              src={toAbsoluteUrl("/media/custom/accountmanager.png")}
              className=""
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <a
              href="https://t.me/danielsup"
              className="text-hover-primary fs-6 cursor-pointer"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <span className="text-dark fw-bold">Telegram</span>
              <span className="ms-1">@danielsup</span>
            </a>
            {/* <a
              href="mailto:daniel@oxapay.com"
              className="d-block text-hover-primary fs-7 cursor-pointer"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <span className="text-muted fw-semibold">Daniel@OxaPay.com</span>
            </a> */}
            {/* <a
              href="https://wa.me/+1"
              target={"_blank"}
              rel="noreferrer"
              className="d-flex align-items-center text-hover-primary fs-7 cursor-pointer"
            >
              <img
                src={toAbsoluteUrl("/media/svg/social-logos/whatsup.svg")}
                className="w-20px me-1"
                alt=""
              />
              <span className="text-muted fw-semibold">+1</span>
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};
